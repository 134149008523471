import $axios from '~/helpers/axios';

export const useModuleStore = defineStore('module', {
    state: () => ({
        module: null
    }),

    actions: {
        getModule() {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get('/modules')
                    .then(res => {
                        if (res.data.success == true) {
                            this.module = res.data.data;
                            response.setStorage('modules', this.module);
                        }
                        resolve(res.data)
                    })
            })
        },
        setModule(payload) {
            this.module = payload
        },
        checkModule(module) {
            if (this.module)
                if (this.module.hasOwnProperty(module))
                    return this.module[module]?.isEnabled;
            return false;
        },
        check(module) {
            let active = false;
            if (this.module?.hasOwnProperty(module))
                active = this.module[module]?.isEnabled ?? false;
            return active;
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useModuleStore, import.meta.hot))
}
