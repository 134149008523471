import { createRouter, createWebHistory } from 'vue-router';
import generatedRoutes from '~pages';

const Router = createRouter({
    history: createWebHistory(),
    routes: generatedRoutes,  // config routes
    //linkActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({
                    left: 0,
                    top: 0,
                    behavior: 'smooth'
                })
            }, 100)
        })
    },
});

export default Router;
