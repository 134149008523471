import navigationList from '~/helpers/navmodule.js';

export const useNavigationStore = defineStore('navigation', {
    state: () => ({
        navigation: navigationList
    }),

    actions: {
        getTabs(payload) {
            const tabs = [];
            const data = this.navigation?.filter(item => item.id == payload)
            data?.forEach(list => {
                list?.children?.forEach(item => {
                    tabs.push(item);
                });
            })

            return tabs
        },
        getNavigation(payload) {
            return this.navigation?.filter(item => item.id == payload)
        }
    },
});


if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useNavigationStore, import.meta.hot))
}
